.footer-isonet-logo {
    vertical-align: -3px;
}

form label.error {
    display: inline-block !important;
}

.contact-form {
    label:not(.error) {
        display: none;
    }
    li {
        color: #ED4B41;
        font-size: 0.8em;
    }
}

@media only screen and (min-width: 992px) {
    .section-about .feature-row .even[class*="col-sm-6"], .team-member-row .even[class*="col-sm-6"] {
        clear: left;
    }
}

.fbox-photo a {
    display: block;
}

.section-logos {
    .logo-item {
        text-align: center;
        line-height: 82px;
        img {
            width: auto;
            display: inline-block;
            vertical-align: middle;
            max-width: 100%;
        }
    }
}

.fill-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.5);
}

.group-poland {
    color: $primary-color;
    font-size: 11px;
    line-height: 14px;
    margin-left: 85px;
}

@media only screen and (max-width : 991px) {
    .group-poland {
        font-size: 8px;
        line-height: 10px;
        margin-left: 52px;
    }
}

@media only screen and (max-width : 767px) {
    .group-poland {
        display: none;
    }
}

.mh-140 {
    min-height: 140px;
}

img.ft-logo {
  max-width: 320px;
}

.footer-widget {
    .social {
        .fa {
            font-size: 32px;
        }
    }
}